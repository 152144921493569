import gamifiedImage from "../assets/images/phones/gamified.png";
import lockImage from "../assets/images/elements/lock.png";
import hatImage from "../assets/images/elements/hat.png";
import handImage from "../assets/images/elements/hand.png";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

export default function GamifiedExperience() {
  return (
    <Container fluid>
      <div
        className="blue"
        style={{
          clear: "both",
          display: "table",
          width: "100%",
          textAlign: "-webkit-center",
          margin: "50px 0",
        }}
      >
        <div className="row gamified  section-row-content" style={{ maxWidth: "2600px" }}>
          <div className="col-lg-6 col-md-12 col-12 text-center d-none d-md-none d-lg-block phone-container">
            <motion.img
              src={gamifiedImage}
              alt="gamifiedImage"
              className="gamified-img"
              whileInView={{ y: ["50%", "0%"] }}
              viewport={{ once: true }}
              initial={{ rotate: 30 }}
              animate={{ rotate: -10 }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />

            <motion.img
              src={lockImage}
              alt="lockImage"
              className="lock-img"
              whileInView={{ y: ["200%", "0%"] }}
              viewport={{ once: true }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />

            <motion.img
              src={handImage}
              alt="handImage"
              className="hand-img"
              whileInView={{ y: ["200%", "0%"] }}
              viewport={{ once: true }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />

            <motion.img
              src={hatImage}
              alt="hatImage"
              className="hat-img"
              whileInView={{ y: ["200%", "0%"] }}
              viewport={{ once: true }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-12 align-self-center text-md-center text-lg-left text-center phone-container">
            <h2
              className="pb-3"
              style={{
                paddingTop: "10%",
              }}
            >
              A gamified experience
            </h2>
            <p className="mb-5">
              Your interaction with the app earns you badges,<br></br>
              these badges can be used to unlock new app buddies
            </p>
            <iframe
              className="character-rive d-block d-md-block d-lg-none"
              title="riveCharacters"
              style={{
                border: "none",
                width: "100%",
              }}
              src="https://rive.app/s/XQiYcT0QGU6z9UL2I2h7Lg/embed"
            ></iframe>
          </div>
        </div>
      </div>
    </Container>
  );
}
