import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useInView } from "react-intersection-observer";

const CircularProgressbarWithSensorBlue = ({}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the visibility is triggered only once
    threshold: 0.1, // Adjust this value as needed
  });

  const percentage = inView ? 81.2 : 0;

  return (
    <div ref={ref}>
      {/* <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}> */}
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        background={true}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 1.5,
          textSize: "12px",
          pathColor: "#1F97DE",
          textColor: "#006DAE",
          trailColor: "#d6d6d6",
          backgroundColor: "#EDF7FF",
        })}
      />
      {/* </VisibilitySensor> */}
    </div>
  );
};

const CircularProgressbarWithSensorGreen = ({}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const percentage = inView ? 85.7 : 0;

  return (
    <div ref={ref}>
      {/* <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}> */}
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        background={true}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 1.5,
          textSize: "12px",
          pathColor: "#6E7C4D",
          textColor: "#0E6C36",
          trailColor: "#d6d6d6",
          backgroundColor: "#F4FFF9",
        })}
      />
      {/* </VisibilitySensor> */}
    </div>
  );
};

const CircularProgressbarWithSensorPurple = ({}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const percentage = inView ? 79.6 : 0;

  return (
    <div ref={ref}>
      {/* <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}> */}
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        background={true}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 1.5,
          textSize: "12px",
          pathColor: "#C781D1",
          textColor: "#58529B",
          trailColor: "#d6d6d6",
          backgroundColor: "#F4F4FF",
        })}
      />
      {/* </VisibilitySensor> */}
    </div>
  );
};

export { CircularProgressbarWithSensorBlue, CircularProgressbarWithSensorGreen, CircularProgressbarWithSensorPurple };
