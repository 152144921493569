import { Nav, Container, Navbar, Card } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Fade as Hamburger } from "hamburger-react";

import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import { CircularProgressbarWithSensorBlue, CircularProgressbarWithSensorGreen, CircularProgressbarWithSensorPurple } from "../components/CircularProgressbarWithSensor";
import GamifiedExperience from "../components/GamifiedExperience";
import FAQAccordions from "../components/FAQAccordions";

import heroPhone from "../assets/images/heroPhone.png";
import bannerredEllispe from "../assets/images/banner/redEllipse.png";
import bannerorangeEllispe from "../assets/images/banner/orangeEllipse.png";
import bannerblueEllispe from "../assets/images/banner/blueEllipse2x.png";
import bannervector from "../assets/images/banner/Vector2x.png";
import Logo from "../assets/images/thriveLogo.png";
import googlePlayButton from "../assets/images/google_play_button.svg";
import iosButton from "../assets/images/ios_button.svg";
import emoLiteracy from "../assets/images/emotionalLiteracy.svg";
import mindfulness from "../assets/images/minfulness.svg";
import productivity from "../assets/images/productivity.svg";
import darkmodeImage from "../assets/images/phones/darkmode.png";
import colorImage from "../assets/images/phones/color.png";
import pinkCircleImage from "../assets/images/elements/pink.png";
import patternsImage from "../assets/images/phones/checkin.png";
import MoodGraphImage from "../assets/images/elements/MoodGraph.png";
import happyWidgetImage from "../assets/images/elements/happyWidget.png";
import mindfulnessImage from "../assets/images/phones/burst.png";
import breathworksImage from "../assets/images/phones/breathworks.png";

import BreathingImg from "../assets/images/elements/Breathing.png";
import WinkImg from "../assets/images/elements/Wink.png";
import WinkGif from "../assets/images/wink_gif.gif";
import PurpleBallImg from "../assets/images/elements/ballGoup.png";

import turnerMonash from "../assets/images/monashTurner.png";
import MonashLogo from "../assets/images/monash-logo.svg";

export default function Welcome() {
  // const [showLogo, setShowLogo] = useState(false);
  const containerVariants = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const [isOpen, setOpen] = useState(false);

  const refChooseStyle = useRef(null);
  const isrefChooseStyleInView = useInView(refChooseStyle, { once: true });

  const refUnderstandBetter = useRef(null);
  const isrefUnderstandBetterView = useInView(refUnderstandBetter, { once: true });

  const refMindfulness = useRef(null);
  const isrefMindfulnessView = useInView(refMindfulness, { once: true });

  const refGoDark = useRef(null);
  const isrefGoDarkView = useInView(refGoDark, { once: true });

  const handleNavLinkClick = () => {
    const element = document.getElementById("navbar-toggler-toggle");
    setOpen(false); // Close the hamburger menu if it's open
  };

  const handleResize = () => {
    const element = document.getElementById("navbar-toggler-toggle");

    if (element) {
      if (window.innerWidth >= 768) {
        element.classList.remove("d-none");
        element.classList.add("justify-content-end", "d-table");
      } else {
        if (!element.classList.contains("show")) {
          element.classList.remove("d-table");
          element.classList.add("d-none");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set the correct class based on the initial viewport width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [
    <Navbar expand="md" className="bg-body-tertiary" bg="white" sticky="top" id="header" expanded={isOpen}>
      <Container fluid style={{ maxWidth: "1400px" }}>
        <Navbar.Brand href="#home" className="logo-visible">
          <img src={MonashLogo} className="d-inline-block align-top ml-md-5" alt="Thrive Logo" style={{ paddingLeft: "1rem" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </Navbar.Toggle>
        <Navbar.Collapse className="justify-content-end" id="navbar-toggler-toggle">
          <Nav className="me-auto my-2 my-md-0" style={{ maxHeight: "100px" }} navbarScroll>
            <Nav.Link href="https://www.monash.edu/thrive/thrive-app" className="menu-item mr-5" onClick={handleNavLinkClick}>
              Monash website
            </Nav.Link>
            <Nav.Link href="#Features" className="menu-item mr-5" onClick={handleNavLinkClick}>
              Features
            </Nav.Link>
            <Nav.Link href="#Help" className="menu-item mr-5" onClick={handleNavLinkClick}>
              Help
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>,

    <Container style={{ paddingTop: "30px" }} id="home">
      {/* ------- banner start------ */}
      <div className="row mb-5 align-items-center" style={{ padding: "0 5%" }}>
        <div className="col-md-6 col-12">
          <div className="row myHeader">
            <div className={"logo-visible"} style={{ marginBottom: "-10px" }}>
              <img src={Logo} style={{ width: "50%", backgroundColor: "white" }} className="d-inline-block align-top" alt="Thrive Logo" />
            </div>

            <h1 className="text-secondary ps-4 mb-2">
              Your <span className="text-highlighted">new</span> mental health <span className="text-highlighted">Companion</span>
            </h1>
            <p className="text-secondary ps-4 pb-4">A personal app and a personal friend, secured on your phone</p>

            <p className="fs-5 fw-bold m-0 px-4" style={{ color: "#2367a0", fontWeight: "600" }}>
              Now Available on
            </p>

            <div className="row py-4">
              <div className="col-lg-5 col-md-5 col-sm-6 col-6 text-right">
                <a href="https://apps.apple.com/in/app/monash-thrive/id1610640249" target="_blank" rel="noopener noreferrer">
                  <img src={iosButton} style={{ width: "90%" }} className="d-inline-block align-top storeButtons" alt="download buttion iOS" />
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-6 col-6 text-left">
                <a href="https://play.google.com/store/apps/details?id=com.monash.thrive.international" target="_blank" rel="noopener noreferrer">
                  <img src={googlePlayButton} style={{ width: "90%" }} className="d-inline-block align-top storeButtons" alt="download buttion Andriod" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <motion.div className="col-md-6 col-12 text-center heroImage">
          <motion.img
            src={heroPhone}
            initial={{ y: "10%", opacity: 0, rotate: 0 }}
            animate={{ y: "0%", opacity: 1, rotate: 10 }}
            transition={{ duration: 1, ease: "easeOut" }}
            width="60%"
            height="auto"
            className="d-inline-block heroPhone align-top base-image"
            alt="Base Image"
          />
          <motion.img
            src={bannerredEllispe}
            width="10%"
            height="auto"
            className="d-inline-block heroPhone align-top overlay-image"
            alt="Red Ellipse"
            style={{ position: "absolute", top: "10%", right: "5%" }}
            initial={{ y: 0 }}
            animate={{ y: [-5, 0, -5] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
          <motion.img
            src={bannerorangeEllispe}
            width="5%"
            height="auto"
            className="d-inline-block heroPhone align-top overlay-image"
            alt="Orange Ellipse"
            style={{ position: "absolute", top: "20%", left: "20%" }}
            initial={{ y: 0 }}
            animate={{ y: [-5, 0, -5] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
          <motion.img
            src={bannerblueEllispe}
            width="8%"
            height="auto"
            className="d-inline-block heroPhone align-top overlay-image"
            alt="Blue Ellipse"
            style={{ position: "absolute", bottom: "15%", right: "16%" }}
            initial={{ y: 0 }}
            animate={{ y: [-2, 0, -2] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
          <motion.img
            src={bannervector}
            width="10%"
            height="auto"
            className="d-inline-block heroPhone align-top overlay-image"
            alt="Vector"
            style={{ position: "absolute", bottom: "20%", left: "15%" }}
            initial={{ y: 0 }}
            animate={{ y: [-2, 0, -2] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
        </motion.div>

        <div className="col-12 mt-4">
          <p style={{ fontSize: "0.85rem", margin: "0", textAlign: "center" }}>
            By downloading the app, it is understood that you have agreed to abide by the{" "}
            <a href="https://www.monash.edu/thrive/thrive-app/terms-of-use" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "#006dae" }}>
              Terms of Use
            </a>
            . Please ensure you are comfortable with these terms prior to downloading the app to enjoy a seamless user experience.
          </p>
        </div>
      </div>
      {/* ------- banner end------ */}

      {/* ------- stats start------ */}
      <div className="stats content-section-padding " style={{ padding: "50px 5%" }}>
        <div className="row statsRow ">
          <div className="col-md-3 col-sm-6">
            <CountUp end={2} enableScrollSpy={true}>
              {({ countUpRef }) => <h2 ref={countUpRef} style={{ display: "inline", clear: "none", color: "#fff" }} />}
            </CountUp>
            <p>Research Trials</p>
          </div>
          <div className="col-md-3 col-sm-6 ">
            <CountUp end={229} enableScrollSpy={true}>
              {({ countUpRef }) => <h2 ref={countUpRef} style={{ display: "inline", clear: "none", color: "#fff" }} />}
            </CountUp>
            <p>Student Participants</p>
          </div>
          <div className="col-md-3 col-sm-6">
            <CountUp end={10} enableScrollSpy={true}>
              {({ countUpRef }) => <h2 ref={countUpRef} style={{ display: "inline", clear: "none", color: "#fff" }} />}
            </CountUp>
            <h2 style={{ display: "inline", clear: "none", color: "#fff" }}>+</h2>
            <p>Focus Groups</p>
          </div>
          <div className="col-md-3 col-sm-6" id="Features">
            <CountUp end={530} enableScrollSpy={true}>
              {({ countUpRef }) => <h2 ref={countUpRef} style={{ display: "inline", clear: "none", color: "#fff" }} />}
            </CountUp>
            <p>Survey Responses</p>
          </div>
        </div>
      </div>
      {/* ------- stats end------ */}

      <svg style={{ height: "60vw", overflow: "visible", position: "absolute", left: "-10%", maxHeight: "650px" }} viewBox="0 0 591 809" fill="none">
        <path
          opacity="0.4"
          d="M122.672 784.701C-66.328 650.701 0.171998 257.201 79.6719 113.2C135.978 11.2098 256.19 -38.2162 367.966 36.5166C479.741 111.25 612.077 251.011 587.256 414.598C562.435 578.185 238.932 897.344 122.672 784.701Z"
          fill="#1F97DE"
        />
      </svg>

      {/* ------- sneak peak start------ */}

      <div className="content-section-spacing content-section-padding">
        <div className="row justify-content-md-center">
          <div className="col-md-12 text-center">
            <h2 className="mb-5">Sneak peak</h2>
            <div class="video-container">
              <iframe
                width="560"
                height="auto"
                src="https://www.youtube.com/embed/JkQ8UfyXQYo?si=01jZQ2z_5P-KNP8K&modestbranding=1&rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen=""
              ></iframe>

              <div class="video-cover"></div>
            </div>
          </div>
        </div>
      </div>

      {/* ------- sneak peak end------ */}

      {/* -------  aim to achieve start ------- */}
      <div className="aim content-section-spacing content-section-padding">
        <div className="row justify-content-md-center">
          <div className="col-md-7 text-center">
            <h2>What we aim to achieve</h2>
            <p>
              Thrive app is designed with students for students and has many functions designed to help you with your mental health and wellbeing throughout your university
              journey.
            </p>
          </div>
        </div>

        <div className="row justify-content-md-center cards my-5" initial="initial" animate="animate" variants={containerVariants} style={{ display: "flex" }}>
          <motion.div className="col-md-4 col-12" initial={{ y: "10%", opacity: 0 }} animate={{ y: "0%", opacity: 1 }} transition={{ duration: 1, ease: "easeOut" }}>
            <Card className="text-center customCard " style={{ border: "none" }}>
              <Card.Img variant="top" src={emoLiteracy} className="card-image" />
              <Card.Body>
                <Card.Title>Emotional Literacy</Card.Title>
                <Card.Text className="customCardText">Increasing awareness about oneself and their emotional behaviors.</Card.Text>
              </Card.Body>
            </Card>
          </motion.div>
          <motion.div className="col-md-4 col-12" initial={{ y: "10%", opacity: 0 }} animate={{ y: "0%", opacity: 1 }} transition={{ duration: 1, ease: "easeOut", delay: 0.3 }}>
            <Card className="text-center customCard " style={{ border: "none" }}>
              <Card.Img variant="top" src={mindfulness} className="card-image" />
              <Card.Body>
                <Card.Title>Increased Mindfulness</Card.Title>
                <Card.Text className="customCardText">Promoting users to live a more mindful existence with helpful tools.</Card.Text>
              </Card.Body>
            </Card>
          </motion.div>
          <motion.div className="col-md-4 col-12" initial={{ y: "10%", opacity: 0 }} animate={{ y: "0%", opacity: 1 }} transition={{ duration: 1, ease: "easeOut", delay: 0.6 }}>
            <Card className="text-center customCard " style={{ border: "none" }}>
              <Card.Img variant="top" src={productivity} className="card-image" />
              <Card.Body>
                <Card.Title>Boost productivity</Card.Title>
                <Card.Text className="customCardText">Help users improve their focus and productivity.</Card.Text>
              </Card.Body>
            </Card>
          </motion.div>
        </div>
      </div>
      {/* -------  aim to achieve end ------- */}

      {/*  -------Choose your style start  ------- */}
      <div className="content-section-spacing content-section-padding" style={{ paddingTop: "0%" }}>
        <div className="row justify-content-center blue preview align-items-center py-5 px-3 p-sm-5 section-row-content">
          <div className="col-lg-5 col-md-10 col-12 choose-your-style-heading">
            <h2 className="pb-2">Choose your style</h2>
            <p>You decide the look of your app, Switch it up with a single tap.</p>
          </div>
          <motion.div
            className="col-lg-7 col-md-12 col-12 text-center"
            initial={{ y: "50%" }}
            animate={{ y: isrefChooseStyleInView ? "0%" : "50%", scale: isrefChooseStyleInView ? [0.3, 1] : [0] }}
            transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
          >
            <img src={colorImage} alt="colorImage app color" className="choose-your-style" />
            <motion.img
              src={pinkCircleImage}
              alt="pinkCircleImage"
              className="choose-your-style-pinkCircleImage"
              initial={{ x: "-100%", y: "100%" }}
              animate={{ x: isrefChooseStyleInView ? "0%" : "-100%", y: isrefChooseStyleInView ? "0%" : "100%" }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />
          </motion.div>
        </div>
        <div ref={refChooseStyle} />
      </div>
      {/*  -------Choose your style end  ------- */}

      {/*  -------Understand yourself better start  ------- */}
      <div className="content-section-spacing content-section-padding">
        <div className="row justify-content-center green preview tilesSection align-items-center section-row-content py-4 px-3 p-sm-5">
          <motion.div
            className="col-lg-7 col-md-12 col-12 text-center mt-5 order-lg-0 order-md-1 order-1 h-100"
            initial={{ y: "50%" }}
            animate={{ y: isrefUnderstandBetterView ? "0%" : "50%", scale: isrefUnderstandBetterView ? [0.3, 1] : [0] }}
            transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
          >
            <img src={patternsImage} alt="patternsImage" className="understand-yourself-better"></img>
            <motion.img
              src={MoodGraphImage}
              alt="MoodGraphImage"
              className="d-inline-block align-top base-image understand-yourself-better-MoodGraphImage"
              initial={{ y: "100%" }}
              animate={{ y: isrefUnderstandBetterView ? "-80%" : "100%" }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />
            <motion.img
              src={happyWidgetImage}
              alt="happyWidgetImage"
              className="d-inline-block align-top base-image understand-yourself-better-happyWidgetImage"
              initial={{ y: "200%" }}
              animate={{ y: isrefUnderstandBetterView ? "0%" : "200%" }}
              transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
            />
          </motion.div>
          <div className="col-lg-5 col-md-10 col-12 order-lg-1 order-md-0 order-0" style={{ height: "40%" }}>
            <h2 className="pb-2">Understand yourself better</h2>
            <p>Check in daily and find out deep insights about your behaviour.</p>
            <p>Monash University will provide you recommendations per your daily check-in</p>
          </div>
        </div>
        <div ref={refUnderstandBetter} />
      </div>
      {/*  -------Understand yourself better end  ------- */}

      {/*  -------Mindfulness and productivity tools start  ------- */}
      <div className="content-section-spacing content-section-padding">
        <div className="row justify-content-center red preview align-items-center section-row-content py-4 px-3 p-sm-5">
          <div className="col-lg-5 col-md-12 col-12 h-25">
            <h2 className="pb-2">Mindfulness and productivity tools</h2>
            <p>Explore Various self help tools such as Breathing exercises, Focus timers and Meditation library</p>
          </div>
          <motion.div
            className="col-lg-7 col-md-10 col-12 text-center"
            initial={{ y: "50%" }}
            animate={{ y: isrefMindfulnessView ? "0%" : "50%", scale: isrefMindfulnessView ? [0.3, 1] : [0] }}
            transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
          >
            <motion.img
              src={mindfulnessImage}
              alt="mindfulnessImage"
              className="d-inline-block align-top base-image burst-focus-mindfulnessImage"
              initial={{ y: 0 }}
              animate={{ y: [-5, 0, -5] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "mirror",
                delay: 1.5,
              }}
            />
            <img src={breathworksImage} alt="breathworksImage" className="d-inline-block align-top base-image breath-fire-breathworksImage"></img>
          </motion.div>
          <div ref={refMindfulness} />
        </div>
      </div>
      {/*  -------Mindfulness and productivity tools end  ------- */}

      {/*  ------- Go Dark, Go Light start  ------- */}
      <div className="content-section-spacing content-section-padding">
        <div className="row justify-content-center dark preview py-4 px-3 p-sm-5 align-items-center section-row-content">
          <div className="col-lg-5 col-md-12 col-12 darkmodeImage-text">
            <h2 className="pb-2">Go Dark, Go Light</h2>
            <p>Do you like it light or dark? </p>
            <p> You can choose the appearance of your app.</p>
          </div>
          <motion.div
            className="col-lg-7 col-md-10 col-12"
            initial={{ y: "50%" }}
            animate={{ y: isrefGoDarkView ? "0%" : "50%", scale: isrefGoDarkView ? [0.3, 1] : [0] }}
            transition={{ type: "spring", stiffness: 129, damping: 14.5, mass: 1 }}
          >
            {/* <img src={darkmodeImage} alt="darkmode" className="darkmodeImage"></img> */}
            <iframe className="darkmodeImage" style={{ border: "none" }} src="https://rive.app/s/-8ck7pqNvUOLIpa-t4wnJQ/embed" allowFullScreen></iframe>
          </motion.div>
          <div ref={refGoDark} />
        </div>
      </div>
      {/*  ------- Go Dark, Go Light end  ------- */}
    </Container>,

    <GamifiedExperience />,

    <Container>
      <div className="content-section-spacing content-section-padding">
        <div className="row blueHeader justify-content-center">
          <div className="col-12 text-center">
            <p>In our trials with our participants</p>
            <h1>How we made our users feel?</h1>
          </div>

          <div className="col-md-4 col-6 resultText text-center" style={{ padding: "5%" }}>
            <CircularProgressbarWithSensorBlue />
            <p className="pt-4">Likely to increase rate of early help seeking</p>
          </div>

          <div className="col-md-4 col-6 resultText text-center" style={{ padding: "5%" }}>
            <CircularProgressbarWithSensorGreen />
            <p className="pt-4">Likely to increase intention / motivation to seek help</p>
          </div>

          <div className="col-md-4 col-6 resultText text-center" style={{ padding: "5%" }}>
            <CircularProgressbarWithSensorPurple />
            <p className="pt-4">Increase awareness of the importance of mental health</p>
          </div>
        </div>

        <div className="col-12 text-center content-section-spacing">
          <p>Want to know more about our research?</p>
          <a className="btn btn-lg btn-research-snapshot" target="_blank" href="https://www.monash.edu/thrive/thrive-app/thrive-app-research" role="button">
            View research snapshot
          </a>
        </div>
      </div>
    </Container>,

    <Container fluid>
      <div className="content-section-spacing content-section-padding" style={{ overflow: "visible" }}>
        <div className="row blueHeader justify-content-center">
          <div className="much-more-img-container" style={{ opacity: "0.6" }}>
            <motion.img
              src={BreathingImg}
              alt="BreathingImg"
              className="breathing-img"
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{
                duration: 50,
                repeat: Infinity,
                repeatType: "mirror",
              }}
            />
            <motion.img
              src={PurpleBallImg}
              alt="PurpleBallImg"
              className="purpleBall-img"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1.2 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "mirror",
              }}
            />
            <img src={WinkGif} alt="WinkImg" className="wink-img" />
          </div>
          <iframe
            className="character-rive d-none d-md-none d-lg-block"
            title="riveCharacters"
            style={{ border: "none", width: "100%" }}
            src="https://rive.app/s/XQiYcT0QGU6z9UL2I2h7Lg/embed"
          ></iframe>
          <div className="col-12 text-center pb-5" id="Help">
            <h1>And much more</h1>
            <p className="customCardText pt-3">Experience it for yourself</p>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-6 text-right">
            <a href="https://apps.apple.com/in/app/monash-thrive/id1610640249" target="_blank" rel="noopener noreferrer">
              <img src={iosButton} className="d-inline-block align-top storeButtons" alt="download buttion iOS" />
            </a>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 text-left">
            <a href="https://play.google.com/store/apps/details?id=com.monash.thrive.international" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayButton} className="d-inline-block align-top storeButtons" alt="download buttion iOS" />
            </a>
          </div>
        </div>
      </div>
    </Container>,

    <FAQAccordions />,

    <Container fluid style={{ padding: "0", margin: "0" }}>
      <div className="footer content-section-padding">
        <div className="row justify-content-center" style={{ marginRight: "0", marginLeft: "0" }}>
          <div className="col-lg-4 col-md-5 col-8 text-center mt-4 mb-4">
            <img src={turnerMonash} className="d-inline-block align-top" style={{ width: "100%", maxWidth: "400px" }} alt="Thrive Logo" />
          </div>
        </div>
        <div className="row justify-content-center" style={{ marginRight: "0", marginLeft: "0" }}>
          <div className="col-10 pb-5 text-center">
            <p className="graphite-intro text--body text--center text--respects">
              We acknowledge and pay respects to the Elders and Traditional Owners of the land on which our four Australian campuses stand.{" "}
              <a href="https://www.monash.edu/indigenous-australians" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline" }}>
                Information for Indigenous Australians
              </a>
            </p>
            <p className="graphite-intro text--footer text--copyright" data-auto-test-id="sec-footer-copyright">
              Copyright © 2023 Monash University. ABN 12 377 614 012{" "}
              <b>
                <a href="https://www.monash.edu/accessibility">Accessibility</a> - <a href="https://www.monash.edu/disclaimer-copyright">Disclaimer and copyright</a> -{" "}
                <a href="https://www.monash.edu/terms-and-conditions">Website terms and conditions</a> -{" "}
                <a href="https://www.monash.edu/privacy-monash">Data Protection and Privacy Procedure</a> - <a href="#">Data Consent Settings</a>
              </b>
              , Monash University CRICOS Provider Number: 00008C, Monash College CRICOS Provider Number: 01857J. Monash University is a registered higher education provider under
              the TEQSA Act 2011.
            </p>
          </div>
        </div>
      </div>
    </Container>,
  ];
}
