import React from "react";
import { Route, Routes } from "react-router-dom";

import Welcome from "../pages/Welcome";

const SiteRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Welcome />} />
    </Routes>
  );
};

export default SiteRoutes;
