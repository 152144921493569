import { Container } from "react-bootstrap";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../includes/Accordion.css";
// import "bootstrap/dist/css/bootstrap.css";

export default function FAQAccordions() {
  return (
    <Container className="content-section-spacing">
      <div className="faqs myHeader content-section-spacing content-section-padding">
        <div className="row justify-content-center text-center">
          <h1 className="col-12 mb-5">FAQs</h1>

          <div className="col-12 mb-5 text-left">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Who is this app for?</Accordion.Header>
                <Accordion.Body>This tool is designed specifically with and for our Monash University students but is available to all Monash students and staff.</Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Does the app cost anything?</Accordion.Header>
                <Accordion.Body>
                  The Monash Thrive app is only available to our Monash students and staff and does <b>NOT</b> cost anything.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>What data do we collect</Accordion.Header>
                <Accordion.Body>
                  We only require your email address to authenticate that you are a valid Monash student or staff, everything else sits on your phone. All your diary and mood
                  entries, activities and personal data is stored offline on your device and can be deleted by you, permanently, at any time by either deleting your account from
                  within the app or uninstalling the app from your phone. Please note that if you accidently delete your account or the app, or even change phones{" "}
                  <b>we cannot restore</b> any data for you as we do not collect it. Please see our further{" "}
                  <a style={{ color: "#006dae", textDecoration: "underline" }} href="https://www.monash.edu/thrive/thrive-app/faqs">
                    FAQs
                  </a>{" "}
                  for more details.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>How secure is the app?</Accordion.Header>
                <Accordion.Body>
                  The app allows you to set your personal pin and turn on biometrics / face ID for your protection, it is a like a personal diary, all data is on the phone, nothing
                  sits on the cloud. <br />
                  <br />
                  If you change phone, delete the app, or delete your account, all your data will be permanently deleted and cannot be restored.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>Does the app work on an iPad or Android tablets?</Accordion.Header>
                <Accordion.Body>No. The Monash Thrive app isn't currently designed to work on any tablets or iPads.</Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="5">
                <Accordion.Header>Will my mental health data get shared with Monash University?</Accordion.Header>
                <Accordion.Body>
                  Monash does <b>NOT</b> have access to any of your mental health data, so you can rest assure, no one will get to it except you.
                </Accordion.Body>
              </Accordion.Item> */}

              <Accordion.Item eventKey="6">
                <Accordion.Header>Which iOS versions are supported?</Accordion.Header>
                <Accordion.Body>
                  We support only <b>iOS 13</b> and above at the moment.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>Which Android versions does the app support?</Accordion.Header>
                <Accordion.Body>
                  At the moment, you can use the app if you have <b>Android 8 or above</b>.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>Is the app available in app stores like Xiaomi Market, App Market (Oppo), Vivo App Store and Huawei AppGallery?</Accordion.Header>
                <Accordion.Body>No, you'll need to download the app from Google Play if you have an Android phone.</Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                <Accordion.Header>Is there an app for Windows phones?</Accordion.Header>
                <Accordion.Body>No, the app is only available for iOS and Android mobile devices at the moment.</Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10">
                <Accordion.Header>I cannot login to the app</Accordion.Header>
                <Accordion.Body>
                  <p style={{ color: "rgb(0 0 0)" }}>
                    If you're a Monash University student or staff, check you've got the most up-to-date version of the app. If still can't log in, try deleting the app and
                    installing it again from the Apple App Store or the Google Play Store (if you reinstall the app without deleting it first, it might not fix the problem).
                  </p>
                  <ul>
                    <li style={{ listStyleType: "square" }}>
                      Make sure you are using a <b>"@monash.edu"</b> or <b>"@student.monash.edu"</b> email or it is incorrect.
                    </li>
                    <li style={{ listStyleType: "square" }}>
                      When verifying your email you must have access your Monash email from the same device the app is installed on, not from another phone or laptop.
                    </li>
                    <li style={{ listStyleType: "square" }}>Make sure you still have access to your Monash email address.</li>
                    <li style={{ listStyleType: "square" }}>Check your internet connection to ensure you are connected to the internet.</li>
                  </ul>

                  <p style={{ color: "rgb(0 0 0)" }}>
                    You can{" "}
                    <a
                      target="_blank"
                      data-stringify-link="mailto:support.thriveapp@monash.edu"
                      delay="150"
                      data-sk="tooltip_parent"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      href="mailto:support.thriveapp@monash.edu"
                      rel="noopener noreferrer"
                      aria-describedby="sk-tooltip-41770"
                      style={{ color: "#006dae", textDecoration: "underline" }}
                    >
                      support.thriveapp@monash.edu
                    </a>{" "}
                    to get help with logging in – just make sure you provide:
                  </p>
                  <ul>
                    <li className="li" style={{ listStyleType: "square" }}>
                      your device type
                    </li>
                    <li style={{ listStyleType: "square" }}>the iOS/Android version on your device</li>
                    <li style={{ listStyleType: "square" }}>your Monash email (so we can get in touch with you if needed)</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </Container>
  );
}
